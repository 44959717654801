<template>
	<!-- <v-sheet class="recurring-main-listing" style="height: calc(100vh - 120px)"> -->
	<div>
		<v-layout class="">
			<!-- <v-flex class="py-0">
				<div class="my-2 ml-4 d-flex" style="height: 35px !important">
					<div class="mr-4 py-2 px-3 rounded blue lighten-4">
						<span class="fw-600 mr-2 cursor-pointer" v-on:click="getListing()">All </span>
					</div>
					<div class="mr-4 py-2 px-3 rounded blue lighten-4">
						<span class="fw-600 mr-2 cursor-pointer" v-on:click="statusFilter('Raised')">Raised : </span>
						<v-chip class="px-2 py-1" label color="blue white--text" small>
							{{ count.raisedRecurring }}
						</v-chip>
					</div>
					<div class="mr-4 py-2 px-3 rounded green lighten-4">
						<span class="fw-600 mr-2 cursor-pointer" v-on:click="statusFilter('Paid')">Paid : </span>
						<v-chip class="px-2 py-1" label color="green white--text" small>
							{{ count.paidRecurring }}
						</v-chip>
					</div>
					<div class="mr-4 py-2 px-3 rounded warning lighten-4">
						<span class="fw-600 mr-2 cursor-pointer" v-on:click="statusFilter('Unpaid')">Unpaid : </span>
						<v-chip class="px-2 py-1" label color="warning white--text" small>
							{{ count.unpaidRecurring }}
						</v-chip>
					</div>
					<div class="mr-4 py-2 px-3 rounded cyan lighten-4">
						<span class="fw-600 mr-2 cursor-pointer" v-on:click="statusFilter('Postponed')"
							>Postponed :
						</span>
						<v-chip class="px-2 py-1" label color="cyan white--text" small>
							{{ count.postponedRecurring }}
						</v-chip>
					</div>

				</div>
			</v-flex> -->

			<v-flex class="py-0 d-flex text-right listing-right">
				<template v-if="selected.length > 0">
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon left>mdi-chevron-down</v-icon>
								Bulk Action
							</v-btn>
						</template>
						<v-list class="py-0">
							<template v-for="(item, i) in listingBulkAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingBulkAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-btn
						depressed
						:disabled="pageLoading"
						tile
						v-on:click="clearSelection"
						color="red lighten-1"
						class="text-white"
					>
						<v-icon left> mdi-close </v-icon>
						Clear Selections
					</v-btn>
				</template>
				<template v-else>
					<!-- <v-flex class="d-flex justify-content-end mr-1">
						<div class="mr-2 fw-600 my-auto">Filter By</div>
						<div class="filterTagSelect ml-3">
							<v-autocomplete
								v-model="filter_user"
								:items="userItems"
								:menu-props="{ bottom: true, offsetY: true }"
								prepend-inner-icon="mdi-filter"
								outlined
								hide-details
								class="filterSelect"
								content-class="filterSelectContent"
							>
							</v-autocomplete>
						</div>
					</v-flex> -->

					<!-- <div class="py-0 w-20"> -->
					<!-- project_status_list -->
					<div style="width: 200px">
						<v-select
							v-model="status"
							class="listing-select"
							hide-details
							:disabled="pageLoading"
							:items="listingStatus"
							v-on:change="statusFilterRows($event)"
							solo
						>
							<template v-slot:item="{ item }">
								<v-list-item-action>
									<v-avatar size="26" :color="item.status_color" :text-color="item.textcolor">
										<span class="white--text">{{ item.status_count }}</span>
									</v-avatar>
								</v-list-item-action>
								<v-list-item-content>
									<v-list-item-title>{{ item.text }}</v-list-item-title>
									<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
								</v-list-item-content>
							</template>
						</v-select>
					</div>
					<!-- </div> -->
					<v-flex class="d-flex justify-content-end pr-1 mt-2">
						<div class="mr-2 mt-1 fw-600">Filter By</div>

						<div class="ml-3 inline-filter">
							<v-autocomplete
								:items="customers"
								clearable
								item-text="company_name"
								:disabled="pageLoading"
								item-value="id"
								v-model="customer_type"
								v-on:click:clear="(defaultFilter = {}), getListing()"
								:menu-props="{ bottom: true, offsetY: true, contentClass: 'rounded-lg' }"
								outlined
								hide-details
								placeholder="Customer"
								@change="commanFilter($event, 'customer')"
							>
								<template #item="{ item, attrs }">
									<div class="w-100 align-center py-1">
										<v-chip size="20" color="cyan" x-small dark label class="pa-1 mb-1 mr-2">
											<span class="fs-11 fw-600">{{ item.barcode }}</span>
										</v-chip>
										<div class="fw-600 text-uppercase">
											{{ item.company_name }}
										</div>
										<div class="text-muted mt-1">
											Draft :
											<v-chip size="20" color="cyan" x-small dark label class="pa-1 mb-1 mr-2">
												<span class="fs-11 fw-600">{{ item.contarct_draft_count }}</span>
											</v-chip>
											Accepted :
											<v-chip size="20" color="green" x-small dark label class="pa-1 mb-1 mr-2">
												<span class="fs-11 fw-600">{{ item.contarct_accepted_count }}</span>
											</v-chip>
											Rejected :
											<v-chip size="20" color="red" x-small dark label class="pa-1 mb-1 mr-2">
												<span class="fs-11 fw-600">{{ item.contarct_reject_count }}</span>
											</v-chip>
											Project Converted :
											<v-chip size="20" color="primary" x-small dark label class="pa-1 mb-1 mr-2">
												<span class="fs-11 fw-600">{{ item.project_convert_count }}</span>
											</v-chip>
										</div>
									</div>
								</template>
								<template #selection="{ item }">
									<div class="d-flex align-center">
										<v-chip size="20" color="cyan" x-small dark label class="pa-1 mb-1 mr-2">
											<span class="fs-11 fw-600">{{ item.barcode }}</span>
										</v-chip>
										<div class="fw-600 text-uppercase">
											{{ item.company_name }}
										</div>
									</div>
								</template>
							</v-autocomplete>
						</div>
						<div class="ml-2 inline-filter">
							<v-autocomplete
								:items="users"
								:menu-props="{ bottom: true, offsetY: true, contentClass: 'rounded-lg' }"
								outlined
								:disabled="pageLoading"
								v-model="user_filter"
								clearable
								v-on:click:clear="(defaultFilter = {}), getListing()"
								style="min-width: 330px !important; width: 330px !important"
								hide-details
								item-text="display_name"
								item-value="id"
								placeholder="Select Sales Person"
								v-on:change="commanFilter($event, 'users')"
							>
								<template #selection="data">
									<v-chip x-small v-bind="data.attrs" :input-value="data.selected">
										<v-avatar left v-if="data.item.profile_img">
											<v-img v-if="data.item.profile_img" :src="data.item.profile_img"></v-img>
											<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
										</v-avatar>

										<span class="text-uppercase text-500">{{ data.item.display_name }}</span>
									</v-chip>
								</template>
								<template #item="{ item }">
									<div class="d-flex py-1">
										<v-avatar class="mr-2">
											<v-img v-if="item.profile_img" :src="item.profile_img" />
											<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
										</v-avatar>
										<div>
											<div class="fw-600 text-uppercase">{{ item.display_name }}</div>
											<div class="fw-500 text-muted">{{ item.email }}</div>
										</div>
									</div>
								</template>
							</v-autocomplete>
						</div>
						<!-- <div class="ml-3">
							<v-autocomplete
								:items="users"
								:menu-props="{ bottom: true, offsetY: true }"
								outlined
								hide-details
								item-text="first_name"
								item-value="id"
								placeholder="Select Sales Person"
								v-on:change="commanFilter($event, 'users')"
							>
								<template v-slot:selection="data">
									<v-chip small v-bind="data.attrs" :input-value="data.selected">
										<v-avatar left v-if="data.item.profile_img">
											<v-img v-if="data.item.profile_img" :src="data.item.profile_img"></v-img>
											<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
										</v-avatar>

										{{ data.item.display_name }}
									</v-chip>
								</template>
								<template v-slot:item="data">
									<template>
										<v-list-item-avatar>
											<img v-if="data.item.profile_img" :src="data.item.profile_img" />
											<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title v-html="data.item.display_name"></v-list-item-title>
										</v-list-item-content>
									</template>
								</template>
							</v-autocomplete>
						</div> -->
						<div class="ml-1">
							<v-btn
								depressed
								color="white"
								class="red darken-4"
								tile
								text
								style="height: 30px; min-width: 0px !important"
								small
								v-on:click="clearFliter"
							>
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</div>

						<template v-if="internaldata == false">
							<!-- style="height: 32px !important; min-width: 0px !important" -->
							<v-btn
								v-if="getPermission('project:create')"
								depressed
								:disabled="pageLoading"
								tile
								:ripple="false"
								color="red darken-4"
								class="text-white ml-2"
								:to="{
									name: 'create-project',
									query: { ...this.$route.query, t: new Date().getTime() },
								}"
							>
								<v-icon left> mdi-plus </v-icon>
								Create
							</v-btn>
						</template>
						<!-- style="height: 32px !important" -->
						<v-btn
							v-if="internaldata == false"
							depressed
							:disabled="pageLoading"
							color="blue darken-4"
							class="text-white ml-1"
							tile
							v-on:click="routeSetting()"
						>
							<v-icon>mdi-cog</v-icon>
						</v-btn>
						<!-- <v-btn
							depressed
							color="blue darken-4"
							class="text-white ml-1"
							tile
							v-on:click="summary_show = !summary_show"
							:disabled="pageLoading"
						>
							<v-icon>mdi-chart-box-outline</v-icon>
							
						</v-btn> -->

						<!-- <v-menu
							v-if="internaldata == false"
							bottom
							left
							origin="center center"
							transition="slide-y-transition"
							rounded="0"
							offset-y
							max-height="400px"
							:close-on-content-click="false"
							content-class="white-background"
							class="my-auto"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									color="blue darken-4"
									class="text-white"
									tile
									:disabled="pageLoading"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>mdi-menu</v-icon>
								</v-btn>
							</template>
							<v-list class="py-0 my-auto">
								<template v-for="(item, i) in listingAction">
									<v-list-item link :key="i" v-on:click="doAction(item.value)">
										<v-list-item-title
											><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
											>{{ item.title }}</v-list-item-title
										>
									</v-list-item>
									<v-divider v-if="listingAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
								</template>
							</v-list>
						</v-menu> -->

						<v-menu
							v-if="internaldata == false"
							bottom
							left
							origin="center center"
							transition="slide-y-transition"
							rounded="0"
							offset-y
							max-height="400px"
							:close-on-content-click="false"
							content-class="white-background"
							class="my-auto"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									color="blue darken-4"
									class="text-white"
									tile
									:disabled="pageLoading"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>mdi-table-edit</v-icon>
								</v-btn>
								<!-- style="height: 32px !important;" -->
							</template>
							<Draggable
								tag="ul"
								v-model="draggableThead"
								class="draggable-group my-auto"
								handle=".draggable-drag-icon"
								v-on:change="updateTable('customer')"
							>
								<template v-for="cols in draggableThead" class="my-auto">
									<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
										<v-checkbox
											dense
											v-model="draggableTheadShow"
											v-bind:value="cols.key"
											:label="cols.name"
											:disabled="cols.fixed || pageLoading"
											color="blue"
											hide-details
											class="mt-0 mb-0"
											v-on:change="updateTableVisiblity('project')"
										></v-checkbox>
										<v-icon
											v-if="!pageLoading && !cols.fixed"
											class="draggable-action draggable-drag-icon"
											right
											color="blue"
											>mdi-drag</v-icon
										>
										<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
									</li>
								</template>
							</Draggable>
						</v-menu>
					</v-flex>

					<!-- <v-flex class="py-1">
						<v-select
							v-model="ptype"
							class="listing-select"
							hide-details
							:disabled="pageLoading"
							:items="projecttypeList"
							solo
							v-on:change="statusFilterRows($event)"
						>
						</v-select>
					</v-flex> -->
					<!-- <v-btn
						depressed
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="viewType = !viewType"
					>
						<v-icon v-if="viewType">mdi-view-list</v-icon>
						<v-icon v-else>mdi-grid</v-icon>
					</v-btn> -->

					<!-- <v-btn
						v-if="internaldata == false"
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white my-auto"
						tile
						v-on:click="drawerFilter = true"
					>
						<v-icon>mdi-filter</v-icon>
					</v-btn> -->
				</template>
			</v-flex>
		</v-layout>
		<v-layout
			class="page-summary"
			:class="summary_show ? 'show' : ''"
			style="display: grid; grid-template-columns: repeat(5, 1fr)"
		>
			<template v-for="(statusData, key) in listingStatus">
				<PageSummeryBox
					:key="key"
					:color="statusData.status_color"
					:text="statusData.text"
					:count="statusData.status_count"
					@click="status != statusData.value && statusFilterRows(statusData.value)"
				/>
			</template>
		</v-layout>
		<v-layout class="page-summary show" v-if="false">
			<v-flex
				:class="`summary-details ${item.color} lighten-5`"
				v-for="(item, index) in status_list"
				:key="index"
				style="border: 2px solid red"
			>
				<v-icon size="30" :color="item.color">mdi-file-chart-outline</v-icon>
				<div :class="`name ${item.color}--text no-wrap`">{{ item.name }}</div>
				<div :class="`number ${item.color}--text`">{{ item.total_count }}</div>
			</v-flex>
		</v-layout>

		<SearchCriteria
			v-if="search_Enabled()"
			:search-fields="listingFilter"
			search-string="Customer #, Project #, Company Name, Contract Title, Billing Type"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<!-- <template v-if="addvanceFilterAarry.length > 0">
			<v-layout class="btx-search-criteria">
				<v-flex md10>
					<h6>Search Criteria</h6>

					<ul>
						<li>
							Addvance Filter
							<span class="search-text"></span>
						</li>
					</ul>
				</v-flex>
				<v-flex md2 class="text-right">
					<v-btn
						depressed
						color="red lighten-1"
						class="text-white"
						tile
						text
						small
						v-on:click="statusFilterRows('all')"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-flex>
			</v-layout>
		</template> -->

		<Dialog :dialog="export_dialog">
			<template v-slot:title> Import Recurrings </template>
			<template v-slot:body> Export </template>
			<template v-slot:action>
				<v-btn tile depressed class="mr-3" @click="export_dialog = false">Close</v-btn>
				<v-btn tile depressed color="blue darken-4 white--text" class="">Import</v-btn>
			</template>
		</Dialog>

		<AdvanceFilter
			:advanceSearch="drawerFilter"
			v-on:close="drawerFilter = false"
			search-endpoint="project"
			search-title="Projects"
		></AdvanceFilter>

		<template>
			<Table
				type="project"
				delete-endpoint="project/"
				detail-route="project-detail"
				update-route="update-project"
				v-on:reload:content="filterRows"
				:page-loading="pageLoading"
				delete-note="All transactions of this member will also be deleted."
			></Table>
		</template>

		<ProjectSoeUrl
			v-if="urlsDialog"
			v-on:close="urlsDialog = false"
			:update-invoice-dialog="urlsDialog"
		>
		</ProjectSoeUrl>
		<!--    <Contact
			v-on:success="(updateDialog = false), getListing()"
			v-on:refress="(updateDialog = false),getListing()"
			:updated-data="singleData"
			v-on:close="updateDialog = false"
			:update-dialog="updateDialog"
            :contract-id="contactId"
            :customer-id="relatedId"

		>
		</		> -->

		<!-- <CreateProject
			v-on:success="(projectDialog = false), getListing()"
			v-on:close="projectDialog = false"
			v-on:refress="(projectDialog = false), getListing()"
			:project-dialog="projectDialog"
			:customer-id="relatedId"
			:project-id="projectId"
			internal
		>
		</CreateProject> -->
	</div>
	<!-- </v-sheet> -->
</template>
<style scoped>
.theme--dark.v-overlay.v-overlay--active {
	z-index: 99 !important;
}

.inline-filter .v-input__control fieldset {
	max-height: 31px !important;
}

.inline-filter .v-input__control .v-input__append-inner {
	margin-top: 2.2px !important;
	margin-bottom: 0 !important;
}
</style>
<script>
//import UpdateInvoice from "@/view/module/components/update-invoice";
//import DatePicker from "@/view/components/DatePicker";
//import Contact from "@/view/module/contract/Create-Contract";
/* import CreateProject from "@/view/module/project/CreateProject.vue"; */
// import { ProjectEventBus } from "@/core/lib/leave.form.lib";

import ListingMixin from "@/core/mixins/listing.mixin";
import HeightMixin from "@/core/mixins/listing.mixin";
import { toSafeInteger } from "lodash";
import { GET, QUERY } from "@/core/services/store/request.module";
import {
	SET_TBODY,
	SET_SHOWING_STRING,
	SET_THEAD,
	SET_FILTER,
	SET_STATUS,
	SET_TOTAL_PAGE,
	SET_CURRENT_PAGE,
	SET_BULK_ACTION,
	SET_ACTION,
} from "@/core/services/store/listing.module";
import { mapGetters } from "vuex";

export default {
	name: "Contract-listing",
	title: "Listing Contract",
	mixins: [ListingMixin, HeightMixin],
	data() {
		return {
			skipBottomHeight: 72,
			export_dialog: false,
			projectDialog: false,
			singleData: {},
			customers: [],
			users: [],
			projectId: 0,
			urlsDialog: false,
			summary_show: true,
			search_query: null,
			actula_date_filter: null,
			drawerFilter: false,
			pageTitle: "Project",
			user_filter: 0,
			customer_type: 0,
			pageBreadcrumbs: [{ text: "Project", disabled: true }],
			endpoint: "project",
			defaultFilter: {},
			count: {},
			pageLoading: false,
			billingType: [
				{ value: "single_billing", text: "Single Billing" },
				{ value: "multiple_billing", text: "Multiple Billing" },
			],
			DataSearch: null,
			project_status_list: [],
			status: "all",
			/* 		addvanceFilterAarry: [], */
			ptype: "project-types",
		};
	},
	props: {
		relatedId: {
			type: Number,
			default: 0,
		},
		internaldata: {
			type: Boolean,
			default: false,
		},
		updatedData: {
			type: Boolean,
			default: false,
		},
		/*  customerId: {
			type: Number,
			default: 0,
		}, */
	},
	watch: {
		relatedId() {
			this.getListing();
		},
		updatedData() {
			this.getListing();
		},
	},
	methods: {
		referess() {
			window.location.reload();
		},
		create_project() {
			this.projectDialog = true;
		},
		getProjectdata(id) {
			this.projectDialog = true;
			this.projectId = id;
		},
		routeSetting() {
			this.$router.push({
				name: "setting",
				query: {
					tab: "project",
				},
			});
		},
		/* onEsc() {
			this.resetSearch();
		},
		resetSearch() {
			const _this = this;
			_this.$nextTick(() => {
				_this.DataSearch = null;
				this.statusFilterRows();
			});
		}, */
		statusFilterRows(value) {
			this.status = value;
			let payload = {
				status: value,
				// search: this.DataSearch || null,
				// related_id: this.relatedId,
				user_filter: this.user_filter ? this.user_filter : null,
				customer_type: this.customer_type ? this.customer_type : null,
			};
			this.allFiltersStatus(payload);
			this.pageLoading = true;
			this.$store
				.dispatch(QUERY, {
					url: `project`,
					data: {
						...payload,
					},
				})
				.then((data) => {
					this.$store.commit(SET_ACTION, data.action);
					this.$store.commit(SET_BULK_ACTION, data.bulk_action);
					this.$store.commit(SET_CURRENT_PAGE, data.current_page);
					this.$store.commit(SET_TOTAL_PAGE, data.total_page);
					this.$store.commit(SET_SHOWING_STRING, data.showing_string);
					this.$store.commit(SET_STATUS, data.status);
					this.$store.commit(SET_FILTER, data.filter);
					this.$store.commit(SET_TBODY, data.tbody);
					this.$store.commit(SET_THEAD, data.thead);
					/* this.addvanceFilterAarry = []; */
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		commanFilter() {
			this.pageLoading = true;

			let payload = {
				status: +this.status ? +this.status : "all",
				user_filter: this.user_filter ? this.user_filter : null,
				customer_type: this.customer_type ? this.customer_type : null,
			};

			this.allFiltersSetting(payload);

			this.$store
				.dispatch(QUERY, {
					url: `project`,
					data: {
						...payload,
					},
				})
				.then((data) => {
					this.$store.commit(SET_ACTION, data.action);
					this.$store.commit(SET_BULK_ACTION, data.bulk_action);
					this.$store.commit(SET_CURRENT_PAGE, data.current_page);
					this.$store.commit(SET_TOTAL_PAGE, data.total_page);
					this.$store.commit(SET_SHOWING_STRING, data.showing_string);
					this.$store.commit(SET_STATUS, data.status);
					this.$store.commit(SET_FILTER, data.filter);
					this.$store.commit(SET_TBODY, data.tbody);
					this.$store.commit(SET_THEAD, data.thead);
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		allFiltersSetting(payload) {
			this.$router.push({
				name: "project",
				query: {
					...payload,
					t: new Date().getTime(),
				},
			});
		},
		clearFliter() {
			(this.customer_type = null),
				(this.user_filter = null),
				(this.defaultFilter = {}),
				this.$nextTick(() => {
					this.getListing();
				});
			this.$router.replace({
				name: "project",
				query: { t: new Date().getTime() },
			});
		},
		getProjectCount() {
			this.$store
				.dispatch(QUERY, {
					url: `project-count`,
				})
				.then((data) => {
					this.count = data;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		getProjectSetting() {
			this.$store
				.dispatch(GET, { url: "project-setting" })
				.then((data) => {
					this.customers = data.customers;
					if (Array.isArray(data.users)) {
						this.users = data.users.filter((user) => user.users_type == "sales");
					} else {
						this.users = [];
					}
					this.project_status_list = data.status;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		allFiltersStatus(payload) {
			this.$router.push({
				name: "project",
				query: {
					...payload,
					t: new Date().getTime(),
				},
			});
		},
		/* getprojecttypelist() {
			this.$store
				.dispatch(GET, { url: "project-typelist" })
				.then((data) => {
					this.projecttypeList = data.list;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		}, */
		/* getprojectstatuslist() {
			this.$store
				.dispatch(GET, { url: "project-status-list" })
				.then((data) => {
					this.project_status_list = data.list;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		}, */
	},
	computed: {
		...mapGetters(["currentUser", "listingStatus"]),
	},
	components: {
		// RecurringKanban,
		// UpdateInvoice,
		/* CreateProject, */
		// DatePicker,
		// Contact

		Dialog: () => import("@/view/components/Dialog"),
		AdvanceFilter: () => import("@/view/pages/leads/AdvanceFilter"),
		ProjectSoeUrl: () => import("@/view/module/project/Project-Seo-Url.vue"),
		PageSummeryBox: () => import("@/view/components/PageSummeryBox"),
	},
	beforeMount() {
		this.user_filter = toSafeInteger(this.$route.query.user_filter);
		this.customer_type = toSafeInteger(this.$route.query.customer_type);
		this.status = this.$route.query.status || "all";
		if ((this.currentUser.role == 1 || this.currentUser.role == 4) && !this.user_filter) {
			this.user_filter = toSafeInteger(this.$route.query.user_filter);
		}
		/* this.defaultFilter = {
			user_filter: this.user_filter ? this.user_filter : null,
			customer_type: this.customer_type ? this.customer_type : null,
		}; */
	},
	mounted() {
		/* 	this.getprojectstatuslist(); */
		//this.getprojecttypelist();
		//this.getRecurringStatus();
		//this.getInvoiceCount();
		/* this.getListing(); */
		this.getProjectCount();
		this.getProjectSetting();
		let filterQuery = this.$route.query;
		this.$router.push({
			name: "project",
			query: {
				...filterQuery,
				t: new Date().getTime(),
			},
		});
		/* if (this.currentUser.role == 1) {
			this.user_filter = this.currentUser.id;
		} */
		/* ProjectEventBus.$on("updateData", () => {
			this.getListing();
		}); */
	},
};
</script>
